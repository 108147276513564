import { height, width } from '@amcharts/amcharts4/.internal/core/utils/Utils';
import { Label, Line, colors } from '@amcharts/amcharts4/core';
import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';
import { filter } from '@amcharts/amcharts4/.internal/core/utils/Iterator';
import { Router } from '@angular/router';
import { months } from 'moment';
import { style } from '@angular/animations';
import { Legend } from '@amcharts/amcharts4/maps';

@Component({
  selector: 'app-warehousesample',
  templateUrl: './warehousesample.component.html',
  styleUrls: ['./warehousesample.component.scss'],
})
export class WarehousesampleComponent implements OnInit {

  role=""
   bubblechar:any;
   monthlyvolume:any;
   monthlycost:any;
   totalbymonth:any;
   piechartvolume:any
   loader=false;
   piechartcost:any;
   datalist:any;
   totalsbytypespartenr:any;
   totalsbylocpartenr:any;
   bubblecharloc:any;
   listtoatlbymonth:any=[];
   avgartorn=0;
   avgcost=0;
   lc:any;

  opt={minimumFractionDigits:0, maximumFractionDigits: 0}
  opt1={minimumFractionDigits:2, maximumFractionDigits: 2}
  
  // tlistmonth=["FY24 August","FY24 September","FY24 October","FY24 November","FY24 December","FY24 January","FY24 February","FY24 March","FY24 April","FY24 May","FY24 June","FY24 July"];
  tlistmonth=["August","September","October","November","December","January","February","March","April","May","June","July"];

  segmentation:any=[]
  warehousedatalist:any=[]
  segmentationlist:any=[]
  constructor(public webapi:WebapiService,public router:Router) { 


    this.loader=true;
    this.role= localStorage.getItem('role')

    webapi.postData({},'get_warehouse_data').then(res=>{
      var response :any= res;
      // this.datalist=JSON.parse(response.lane);
      // var list= Object.keys(this.datalist[0])

      // this.listmonth=JSON.parse(JSON.stringify(this.tlistmonth));
      // this.calculations();
      // this. get_disticnt_partner();
      // this.dashboardgraphs();
    

    webapi.postData({},'get_warehouse_data_all').then(res=>{

      console.log(res);

      var result:any=res;
     this.warehousedatalist = result.warehouse;

     console.log(this.warehousedatalist);
     this.warehousedatalist.sort((a, b) => {
      const yearA = parseInt(a.year.replace('FY', ''), 10);
      const yearB = parseInt(b.year.replace('FY', ''), 10);
      return yearA - yearB;  // Ascending order
    });
     

     this.segmentationlist=result.segmentation;
     this.segmentationlist.sort((a, b) => {
      const yearA = parseInt(a.year.replace('FY', ''), 10);
      const yearB = parseInt(b.year.replace('FY', ''), 10);
      return yearA - yearB;  // Ascending order
    });
     
      console.log(Object.keys(this.warehousedatalist[0]));

     
      this.change_fiscal_year();

      this.loader=false;

    });

  });
  

    
  }

  selectedfiscal=0;
   sortByMultipleKeys(array, criteria) {
    return array.sort((a, b) => {
      for (let i = 0; i < criteria.length; i++) {
        const { key, order = 'asc' } = criteria[i];
  
        let valueA = a[key];
        let valueB = b[key];
  
        // If values are strings, you can use localeCompare for proper comparison
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          valueA = valueA.toLowerCase();
          valueB = valueB.toLowerCase();
        }
  
        // For numbers or other types, use normal comparison
        let comparison = 0;
        if (valueA < valueB) comparison = -1;
        if (valueA > valueB) comparison = 1;
  
        // If the order is descending, flip the comparison
        if (order === 'desc') {
          comparison *= -1;
        }
  
        // If the values are different, return the comparison result
        if (comparison !== 0) return comparison;
      }
      // If all keys are the same, return 0 (no change)
      return 0;
    });
  }

  change_fiscal_year(){

    var keymap={
      "Partner": "Partner",
      "Function/Site": "Function/Site",
      "Location": "Location",
      "Region": "Region",
      "Regioncode": "Regioncode",
      "Annual Spend FY24": "Annual Spend FY24",
      "Space": "Space",
      "Sq ft": "Sq ft",
      "Pallets": "Pallets",
      "Volume For Q1": "Q1FY24",
      "Volume For August": "August",
      "Volume For September": "September",
      "Volume For October": "October",
      "Volume For Q2": "Q2FY24",
      "Volume For November": "November",
      "Volume For December": "December",
      "Volume For January": "January",
      "Volume For Q3": "Q3FY24",
      "Volume For February": "February",
      "Volume For March": "March",
      "Volume For April": "April",
      "Volume For Q4": "Q4FY24",
      "Volume For May": "May",
      "Volume For June": "June",
      "Volume For July": "July",
      "Invoice Spend For Q1": "Q1FY24.1",
      "Invoice Spend For August": "August.1",
      "Invoice Spend For September": "September.1",
      "Invoice Spend For October": "October.1",
      "Invoice Spend For Q2": "Q2FY24.1",
      "Invoice Spend For November": "November.1",
      "Invoice Spend For December": "December.1",
      "Invoice Spend For January": "January.1",
      "Invoice Spend For Q3": "Q3FY24.1",
      "Invoice Spend For February": "February.1",
      "Invoice Spend For March": "March.1",
      "Invoice Spend For April": "April.1",
      "Invoice Spend For Q4": "Q4FY24.1",
      "Invoice Spend For May": "May.1",
      "Invoice Spend For June": "June.1",
      "Invoice Spend For July": "July.1",
      "Start Date": "Start Date",
      "End Date": "End Date",
      "notes": "notes",
      "Annual Increase": "Annual Increase",
      "Site Utilization For August": "August.2",
      "Site Utilization For September": "September.2",
      "Site Utilization For October": "October.2",
      "Site Utilization For November": "November.2",
      "Site Utilization For December": "December.2",
      "Site Utilization For January": "January.2",
      "Site Utilization For February": "February.2",
      "Site Utilization For March": "March.2",
      "Site Utilization For April": "April.2",
      "Site Utilization For May": "May.2",
      "Site Utilization For June": "June.2",
      "Site Utilization For July": "July.2",
      "Peak Utilization For August": "August.3",
      "Peak Utilization For September": "September.3",
      "Peak Utilization For October": "October.3",
      "Peak Utilization For November": "November.3",
      "Peak Utilization For December": "December.3",
      "Peak Utilization For January": "January.3",
      "Peak Utilization For February": "February.3",
      "Peak Utilization For March": "March.3",
      "Peak Utilization For April": "April.3",
      "Peak Utilization For May": "May.3",
      "Peak Utilization For June": "June.3",
      "Peak Utilization For July": "July.3"
    }

    this.segmentation=[];
    this.datalist=[]

    var list=  JSON.parse(this.warehousedatalist[this.selectedfiscal].data);

    if(this.selectedfiscal<this.segmentationlist.length)
     this.segmentation=JSON.parse(this.segmentationlist[this.selectedfiscal].data);



    this.datalist= this.renameKeys(list, keymap);

   
    this.datalist= this.sortByMultipleKeys(this.datalist, [
      { key: 'Partner', order: 'asc' },   // Sort by name ascending
      { key: 'Function/Site', order: 'asc' },    // If names are the same, sort by age descending
      { key: 'Location', order: 'asc' },  // If name and age are the same, sort by score ascending
    ])
    console.log(this.segmentation);

    this.listmonth=JSON.parse(JSON.stringify(this.tlistmonth));
    this.calculations();
    this. get_disticnt_partner();
    this.dashboardgraphs();

  }

   renameKeys(array, keyMap) {
    return array.map(item => {
      const newItem = {};
  
      // Iterate over each key in the original object
      for (const key in item) {
        // If the key exists in the keyMap, use the new key
        const newKey = keyMap[key] || key;
        newItem[newKey] = item[key];
      }
  
      return newItem;
    });
  }

  
  totalsbytypes:any=[]
  listmonth=[]
  distincttype:any=[]
  region:any;
  totalpartnerlocbymont:any=[]
  costbytype:any=[]
  monlthgraphs:any=[]
  month=['Aug','Sept','Oct','Nov','Dec','Jan','Feb','Mar',"Apr",'May','June','Jul']
  partnersummary:any=[];

  invoicerange="";
  utilizationrange="";
  volumerange="";
  loctyprebk:any=[]
 
  calculations() {

    this.distincttype = this.datalist
       .map((item) => item['Function/Site'])
       .filter(
           (value, index, current_value) => current_value.indexOf(value) === index
       );


    var result = [];
    var list=[]
    var listtoatl=[]

    this.totalpartnerlocbymont=[]
    this.moonthlygraphlist=[]
    this.monlthgraphs=[]


    this.listmonth.forEach(element => {

      listtoatl.push({month:element,totalcarton:0,totalcost:0})
      this.monlthgraphs.push({month:element,totalcarton:0,totalcost:0,space:0})

      this.moonthlygraphlist.push({month:element,graph:undefined,datatable:[["Id","Carton Count","Invoice ($)","Partner","Space (Sq.Ft)"]],graphtype:undefined,datatabletype:[["Id","Utilization (%)","Space (Sq.ft)","Function/Site","Carton Volume"]]})
      this.totalpartnerlocbymont.push({month:element,totalcarton:0,totalcost:0,space:9,loc:"",partner:"",utilization:0,totalpeak:0})
    });

    this.distincttype.forEach(element => {
      var ctx=this;
      var lsttotal=JSON.parse(JSON.stringify(listtoatl));

      var list=  this.datalist.filter(function(item:any){
          return  item['Function/Site']==element;     
          ////////console.log("1")     
      });
      
      list.forEach(value => {
      
        lsttotal.forEach(el => {

          var m=0,c=0;
          if(value[el.month]!=null)
            m= parseFloat(value[el.month]+"")
  
          if(value[el.month+'.1']!=null)
            c= parseFloat(value[el.month+'.1']+"")
          el.totalcost = el.totalcost +c; 
          el.totalcarton = el.totalcarton +m; 
  
        });

      });

      this.costbytype.push({type:element,montlist:lsttotal,graph:undefined})


    });

    //console.log(this.costbytype);
   
    this.datalist.forEach(element => {
      element['partnertype']=element.Partner+'/'+element['Function/Site'];
      element['partnerloc']=element.Partner+'/'+element['Location'];
      element['loctype']=element['Function/Site']+'/'+element['Location'];
      element['Region']=element['Region']+'/'+element['Regioncode'];

      
     
      listtoatl.forEach(el => {

        var m=0,c=0;
        if(element[el.month]!=null)
          m= parseFloat(element[el.month]+"")

        if(element[el.month+'.1']!=null)
          c= parseFloat(element[el.month+'.1']+"")
        el.totalcost = el.totalcost +c; 
        el.totalcarton = el.totalcarton +m; 

      });



      // var value=element;
      // var q1=0,q2=0,q3=0,q4=0;
      // if(value['Q1FY24']!=null)
      //   q1=value['Q1FY24']
      // if(value['Q2FY24']!=null)
      //   q2=value['Q2FY24']
      // if(value['Q3FY24']!=null)
      //   q3=value['Q3FY24']
      // if(value['Q4FY24']!=null)
      //   q4=value['Q4FY24']

      // var q1c=0,q2c=0,q3c=0,q4c=0;
      // if(value['Q1FY24.1']!=null)
      //   q1c=value['Q1FY24.1']
      // if(value['Q2FY24.1']!=null)
      //   q2c=value['Q2FY24.1']
      // if(value['Q3FY24.1']!=null)
      //   q3c=value['Q3FY24.1']
      // if(value['Q4FY24.1']!=null)
      //   q4c=value['Q4FY24.1']


      // var q1s=0,q2s=0,q3s=0,q4s=0;
      // if(value['Sq ft']!=null)
      //   q1s=value['Sq ft']
      // if(value['Sq ft']!=null)
      //   q2s=value['Sq ft']
      // if(value['Sq ft']!=null)
      //   q3s=value['Sq ft']
      // if(value['Sq ft']!=null)
      //   q4s=value['Sq ft']
      list.push(element);
    });
    //console.log(listtoatl);

    var totalcarton=0;
    var totalcost=0;
    listtoatl.forEach(element => {
      totalcarton=totalcarton+element.totalcarton;
      totalcost=totalcost+element.totalcost;

    });
    this.avgartorn=totalcarton;
    this.avgcost=totalcost;
    this.listtoatlbymonth=JSON.parse(JSON.stringify(listtoatl))
    var ctx=this;
        
    this.datalist.reduce(function(res, value) {
      
      if (!res[value['Function/Site']]) {
        res[value['Function/Site']] = { type: value['Function/Site'], totalcarton: 0, totalcost: 0 ,space:0 ,monthlist:JSON.parse(JSON.stringify(ctx.totalpartnerlocbymont))};
        result.push(res[value['Function/Site']])
      }

      var q1=0,q2=0,q3=0,q4=0;
      if(value['Q1FY24']!=null)
        q1=value['Q1FY24']
      if(value['Q2FY24']!=null)
        q2=value['Q2FY24']
      if(value['Q3FY24']!=null)
        q3=value['Q3FY24']
      if(value['Q4FY24']!=null)
        q4=value['Q4FY24']

      var q1c=0,q2c=0,q3c=0,q4c=0;
      if(value['Q1FY24.1']!=null)
        q1c=value['Q1FY24.1']
      if(value['Q2FY24.1']!=null)
        q2c=value['Q2FY24.1']
      if(value['Q3FY24.1']!=null)
        q3c=value['Q3FY24.1']
      if(value['Q4FY24.1']!=null)
        q4c=value['Q4FY24.1']


      var q1s=0,q2s=0,q3s=0,q4s=0;
      if(value['Sq ft']!=null)
        q1s=value['Sq ft']
      // if(value['Sq ft']!=null)
      //   q2s=value['Sq ft']
      // if(value['Sq ft']!=null)
      //   q3s=value['Sq ft']
      // if(value['Sq ft']!=null)
      //   q4s=value['Sq ft']


      
      res[value['Function/Site']].totalcarton += q1+q2+q3+q4;
      res[value['Function/Site']].totalcost += q1c+q2c+q3c+q4c;
      res[value['Function/Site']].space += q1s;

      res[value['Function/Site']].monthlist.forEach(ml => {
        var mv=0,mc=0;
        if(value[ml.month]!=null)
           mv=value[ml.month];
        
        if(value[ml.month+".1"]!=null)
          mc=value[ml.month+".1"];
           
        ml.totalcarton=ml.totalcarton+mv;
        ml.totalcost=ml.totalcost+mc;
        // ml.space=ml.space+q1s;
        // ml.partner=res[value['partnerloc']].partner
        // ml.loc=res[value['partnerloc']].loc

      });
      return res;
    }, {});
    
    var region:any=[];
    this.datalist.reduce(function(res, value) {
      
      if (!res[value['Region']]) {
        res[value['Region']] = { type: value['Region'], totalcarton: 0, totalcost: 0 ,space:0 ,monthlist:JSON.parse(JSON.stringify(ctx.totalpartnerlocbymont))};
        region.push(res[value['Region']])
      }

      var q1=0,q2=0,q3=0,q4=0;
      if(value['Q1FY24']!=null)
        q1=value['Q1FY24']
      if(value['Q2FY24']!=null)
        q2=value['Q2FY24']
      if(value['Q3FY24']!=null)
        q3=value['Q3FY24']
      if(value['Q4FY24']!=null)
        q4=value['Q4FY24']

      var q1c=0,q2c=0,q3c=0,q4c=0;
      if(value['Q1FY24.1']!=null)
        q1c=value['Q1FY24.1']
      if(value['Q2FY24.1']!=null)
        q2c=value['Q2FY24.1']
      if(value['Q3FY24.1']!=null)
        q3c=value['Q3FY24.1']
      if(value['Q4FY24.1']!=null)
        q4c=value['Q4FY24.1']


      var q1s=0,q2s=0,q3s=0,q4s=0;
      if(value['Sq ft']!=null)
        q1s=value['Sq ft']
      // if(value['Sq ft']!=null)
      //   q2s=value['Sq ft']
      // if(value['Sq ft']!=null)
      //   q3s=value['Sq ft']
      // if(value['Sq ft']!=null)
      //   q4s=value['Sq ft']


      
      res[value['Region']].totalcarton += q1+q2+q3+q4;
      res[value['Region']].totalcost += q1c+q2c+q3c+q4c;
      res[value['Region']].space += q1s;

      res[value['Region']].monthlist.forEach(ml => {
        var mv=0,mc;
        if(value[ml.month]!=null)
           mv=value[ml.month];
        
        if(value[ml.month+".1"]!=null)
          mc=value[ml.month+".1"];
           
        ml.totalcarton=ml.totalcarton+mv;
        ml.totalcost=ml.totalcost+mc;
        // ml.space=ml.space+q1s;
        // ml.partner=res[value['partnerloc']].partner
        // ml.loc=res[value['partnerloc']].loc

      });
      return res;
    }, {});

    this.region=region;
     var resultp=[]

    this.datalist.reduce(function(res, value) {
      if (!res[value['Partner']]) {
        res[value['Partner']] = { type: value['Partner'], totalcarton: 0, totalcost: 0 ,space:0 ,utilization:0 };
        resultp.push(res[value['Partner']])
      }

      var q1=0,q2=0,q3=0,q4=0;
      if(value['Q1FY24']!=null)
        q1=value['Q1FY24']
      if(value['Q2FY24']!=null)
        q2=value['Q2FY24']
      if(value['Q3FY24']!=null)
        q3=value['Q3FY24']
      if(value['Q4FY24']!=null)
        q4=value['Q4FY24']

      var q1c=0,q2c=0,q3c=0,q4c=0;
      if(value['Q1FY24.1']!=null)
        q1c=value['Q1FY24.1']
      if(value['Q2FY24.1']!=null)
        q2c=value['Q2FY24.1']
      if(value['Q3FY24.1']!=null)
        q3c=value['Q3FY24.1']
      if(value['Q4FY24.1']!=null)
        q4c=value['Q4FY24.1']


      var q1s=0,q2s=0,q3s=0,q4s=0;
      if(value['Sq ft']!=null)
        q1s=value['Sq ft']
      


      
      res[value['Partner']].totalcarton += q1+q2+q3+q4;
      res[value['Partner']].totalcost += q1c+q2c+q3c+q4c;
      res[value['Partner']].space += q1s;


      return res;
    }, {});

    var resultparner=[]
    
    list.reduce(function(res, value) {
      if (!res[value['partnertype']]) {
        res[value['partnertype']] = { partner:value['Partner'],type: value['Function/Site'], totalcarton: 0, totalcost: 0 ,space:0 };
        resultparner.push(res[value['partnertype']])
      }

      var q1=0,q2=0,q3=0,q4=0;
      if(value['Q1FY24']!=null)
        q1=value['Q1FY24']
      if(value['Q2FY24']!=null)
        q2=value['Q2FY24']
      if(value['Q3FY24']!=null)
        q3=value['Q3FY24']
      if(value['Q4FY24']!=null)
        q4=value['Q4FY24']

      var q1c=0,q2c=0,q3c=0,q4c=0;
      if(value['Q1FY24.1']!=null)
        q1c=value['Q1FY24.1']
      if(value['Q2FY24.1']!=null)
        q2c=value['Q2FY24.1']
      if(value['Q3FY24.1']!=null)
        q3c=value['Q3FY24.1']
      if(value['Q4FY24.1']!=null)
        q4c=value['Q4FY24.1']


      var q1s=0,q2s=0,q3s=0,q4s=0;
      if(value['Sq ft']!=null)
        q1s=value['Sq ft']
      


      
      res[value['partnertype']].totalcarton += q1+q2+q3+q4;
      res[value['partnertype']].totalcost += q1c+q2c+q3c+q4c;
      res[value['partnertype']].space += q1s;


      return res;
    }, {});
    //console.log(resultparner);


    //Bubble chart by partner type 
    var resultloc=[]
    var ctx=this;
    list.reduce(function(res, value) {
      if (!res[value['partnerloc']]) {
        res[value['partnerloc']] = { partner:value['Partner'],loc: value['Location'], totalcarton: 0, totalcost: 0 ,space:0,monthlist:JSON.parse(JSON.stringify(ctx.totalpartnerlocbymont))};
        resultloc.push(res[value['partnerloc']])
      }

      
      var q1=0,q2=0,q3=0,q4=0;
      if(value['Q1FY24']!=null)
        q1=value['Q1FY24']
      if(value['Q2FY24']!=null)
        q2=value['Q2FY24']
      if(value['Q3FY24']!=null)
        q3=value['Q3FY24']
      if(value['Q4FY24']!=null)
        q4=value['Q4FY24']

      var q1c=0,q2c=0,q3c=0,q4c=0;
      if(value['Q1FY24.1']!=null)
        q1c=value['Q1FY24.1']
      if(value['Q2FY24.1']!=null)
        q2c=value['Q2FY24.1']
      if(value['Q3FY24.1']!=null)
        q3c=value['Q3FY24.1']
      if(value['Q4FY24.1']!=null)
        q4c=value['Q4FY24.1']


      var q1s=0
      if(value['Sq ft']!=null)
        q1s=value['Sq ft']
     


      
      


      
      res[value['partnerloc']].totalcarton += q1+q2+q3+q4;
      res[value['partnerloc']].totalcost += q1c+q2c+q3c+q4c;
      res[value['partnerloc']].space += q1s
      
      res[value['partnerloc']].monthlist.forEach(ml => {
        var mv=0,mc;
        if(value[ml.month]!=null)
           mv=value[ml.month];
        
        if(value[ml.month+".1"]!=null)
          mc=value[ml.month+".1"];
           
        ml.totalcarton=ml.totalcarton+mv;
        ml.totalcost=ml.totalcost+mc;
        // ml.space=ml.space+q1s;
        ml.partner=res[value['partnerloc']].partner
        ml.loc=res[value['partnerloc']].loc

      });
      

      return res;
    }, {});
    //console.log(resultloc);


    //Bubble chart by type

    var resultloctype=[]
    var ctx=this;
    list.reduce(function(res, value) {
      if (!res[value['loctype']]) {
        res[value['loctype']] = { p:value['Partner'],partner:value['Function/Site'],loc: value['Location'], totalpeak: 0, totalcarton: 0, totalcost: 0 ,space:0,monthlist:JSON.parse(JSON.stringify(ctx.totalpartnerlocbymont))};
        resultloctype.push(res[value['loctype']])
      }

      
      var q1=0,q2=0,q3=0,q4=0;
      if(value['Q1FY24']!=null)
        q1=value['Q1FY24']
      if(value['Q2FY24']!=null)
        q2=value['Q2FY24']
      if(value['Q3FY24']!=null)
        q3=value['Q3FY24']
      if(value['Q4FY24']!=null)
        q4=value['Q4FY24']

      // var q1c=0,q2c=0,q3c=0,q4c=0;
      // if(value['Q1FY24.1']!=null)
      //   q1c=value['Q1FY24.1']
      // if(value['Q2FY24.1']!=null)
      //   q2c=value['Q2FY24.1']
      // if(value['Q3FY24.1']!=null)
      //   q3c=value['Q3FY24.1']
      // if(value['Q4FY24.1']!=null)
      //   q4c=value['Q4FY24.1']

      var ut=0;
      var peak=0;
      ctx.listmonth.forEach(mth => {
        

        var mc=0;
        if(value[mth+".2"]!=null)
          mc=value[mth+".2"];

        var pk=0;
        if(value[mth+".3"]!=null)
          pk=value[mth+".3"];

        peak=peak+pk;
        ut=ut+mc;

        //console.log(ut);
      });

      var q1s=0
      if(value['Sq ft']!=null)
        q1s=value['Sq ft']
     


      
      


      
      res[value['loctype']].totalcarton += q1+q2+q3+q4;
      res[value['loctype']].totalcost += (ut/12);
      res[value['loctype']].totalpeak += (peak/12);

      res[value['loctype']].space += q1s
      
      res[value['loctype']].monthlist.forEach(ml => {
        var mv=0,mc=0,peak=0;
        if(value[ml.month]!=null)
           mv=value[ml.month];
        
        if(value[ml.month+".2"]!=null&&value[ml.month+".2"]!=''&&value[ml.month+".2"]!=0)
          mc=value[ml.month+".2"];

        if(value[ml.month+".3"]!=null)
          peak=value[ml.month+".3"];
           
        ml.totalcarton=ml.totalcarton+mv;
        ml.totalcost=ml.totalcost+(mc);
        ml.totalpeak=ml.totalpeak+(peak);

        ml.space=ml.space+q1s;
        ml.partner=res[value['loctype']].partner
        ml.loc=res[value['loctype']].loc

      });
      

      return res;
    }, {});


    console.log(resultloctype)

    //partner

    var resultpartner=[]
    var ctx=this;
    list.reduce(function(res, value) {
      if (!res[value['Partner']]) {
        res[value['Partner']] = { partner:value['Partner'],count:0, totalcarton: 0, totalcost: 0 ,space:0,utilization:0,monthlist:JSON.parse(JSON.stringify(ctx.totalpartnerlocbymont))};
        resultpartner.push(res[value['Partner']])
      }

      
      var q1=0,q2=0,q3=0,q4=0;
      if(value['Q1FY24']!=null)
        q1=value['Q1FY24']
      if(value['Q2FY24']!=null)
        q2=value['Q2FY24']
      if(value['Q3FY24']!=null)
        q3=value['Q3FY24']
      if(value['Q4FY24']!=null)
        q4=value['Q4FY24']

      var q1c=0,q2c=0,q3c=0,q4c=0;
      if(value['Q1FY24.1']!=null)
        q1c=value['Q1FY24.1']
      if(value['Q2FY24.1']!=null)
        q2c=value['Q2FY24.1']
      if(value['Q3FY24.1']!=null)
        q3c=value['Q3FY24.1']
      if(value['Q4FY24.1']!=null)
        q4c=value['Q4FY24.1']

      var ut=0;
      ctx.listmonth.forEach(mth => {
        

        var mc=0;
        if(value[mth+".2"]!=null&&value[mth+".2"]!=''&&value[mth+".2"]!=0)
          mc=value[mth+".2"];

        ut=ut+mc;
        //console.log(ut);
      });

      var q1s=0
      if(value['Sq ft']!=null)
        q1s=value['Sq ft']
     


      
      


      
      res[value['Partner']].totalcarton += q1+q2+q3+q4;
      res[value['Partner']].utilization += (ut/12);
      res[value['Partner']].totalcost += q1c+q2c+q3c+q4c;
      res[value['Partner']].count=res[value['Partner']].count+1;
      res[value['Partner']].space += q1s
      
      res[value['Partner']].monthlist.forEach(ml => {
        var mv=0,mc=0,mi=0;
        if(value[ml.month]!=null)
           mv=value[ml.month];
        
        if(value[ml.month+".2"]!=null&&value[ml.month+".2"]!=''&&value[ml.month+".2"]!=0)
          mc=value[ml.month+".2"];

        if(value[ml.month+".1"]!=null)
          mi=value[ml.month+".1"];
           
        ml.totalcarton=ml.totalcarton+mv;
        ml.utilization=ml.utilization+(mc);
        ml.totalcost=ml.totalcost+(mi);

        ml.space=ml.space+q1s;
        ml.partner=res[value['Partner']].partner
        ml.loc=res[value['Partner']].loc

      });
      

      return res;
    }, {});

    this.partnersummary=resultpartner;

    this.totalsbyloctype=resultloctype;
    this.loctyprebk=JSON.parse(JSON.stringify(resultloctype));

    this.totalsbytypes=result;
    this.totalsbytypespartenr=resultparner;
    this.totalsbylocpartenr=resultloc;
    this.totabypartner=resultp;

    this.drawgraphs(); 
    this.drawguagegraph();
    this.site = this.totalsbyloctype
    .map((item) => item['partner'])
    .filter(
        (value, index, current_value) => current_value.indexOf(value) === index
    );

  }


  site:any=[]
  guagegraphs:any=[]
  totalsbyloctype:any=[]
  totabypartner:any[]
  ngOnInit() {
   // this.drawguagegraph();
   this.webapi.addaccesslog("/warehouse");

  }

  selectedgraph:any;
  moonthlygraphlist:any=[]

  drawgraphs(){

    var LineChartdatakarea = {
      chartType: 'BubbleChart',
      dataTable: [],
      options: {
        options: {
  
          title: "Volume vs Invoice ($)",
          
        },
        hAxis: {title: 'Volume',textPosition:'none'},
        vAxis: {title: 'Invoice ($)',textPosition:'none'},
       
        animation:{
          duration: 1000,
          easing: 'out',
          startup:true
        },
        legend: { position: 'top', alignment: 'center' },

        //series: {},
        // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
        
        colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589'],
        // ,
        'chartArea': {'width': '85%', 'height': '70%'},

      height:220,
      }
  
    }
    var LineChartdatakareatype = {
      chartType: 'BubbleChart',
      dataTable: [],
      options: {
        options: {
  
          title: "Volume vs Invoice ($)",
          
        },
        hAxis: {title: 'Utilization',textPosition:'none', gridlines: {minSpacing:10}},
        vAxis: {title: 'Space (Sq. Ft)',textPosition:'none', gridlines: {minSpacing:10}},
        // bubble: {textStyle: {color: 'none'}},
        // hAxis: {  gridlines: {
        //   color: 'transparent'
        // }},
        // vAxis :{
        //   gridlines: {
        //     color: 'transparent'
        //   }
        // },
        // pointsize:3,
        animation:{
          duration: 1000,
          easing: 'out',
          startup:true
        },
        legend: { position: 'top', alignment: 'center' },

        //series: {},
        // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
        
        colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589'],
        // ,
        'chartArea': {'width': '85%', 'height': '90%'},

      height:540,
      }
  
    }


     var datatable=[["Id","Carton Count","Invoice ($)","Type","Space (Sq.Ft)"]];
     this.totalsbytypespartenr.forEach(element => {

      datatable.push([element.partner,element.totalcarton,element.totalcost,element.type,element.space])
      
     });
     LineChartdatakarea.dataTable =datatable;
    this.bubblechar=JSON.parse(JSON.stringify(LineChartdatakarea));
    //console.log(this.bubblechar.dataTable)


    var datatable=[["Id","Carton Count","Invoice ($)","Partner","Space (Sq.Ft)"]];
       var datatabletype=[["Id","Utilization (%)","Space (Sq.ft)","Function/Site","Carton Volume"]];

    var m=0;

    this.listmonth.forEach(elm => {
      
  

      this.totalsbylocpartenr.forEach(element => {

        this.moonthlygraphlist[m].datatable.push([element.monthlist[m].loc,element.monthlist[m].totalcarton,element.monthlist[m].totalcost*100,element.monthlist[m].partner,element.monthlist[m].space])

         datatable.push([element.loc,element.totalcarton,element.totalcost*100,element.partner,element.space])
     
      });
      this.totalsbyloctype.forEach(element => {

        this.moonthlygraphlist[m].datatabletype.push([element.monthlist[m].loc,element.monthlist[m].totalcost*100,element.monthlist[m].space,element.monthlist[m].partner,element.monthlist[m].totalcarton])

        datatabletype.push([element.loc,element.totalcost*100,element.space,element.partner,element.totalcarton])

      });

      LineChartdatakarea.options.height=540

      LineChartdatakarea.dataTable =this.moonthlygraphlist[m].datatable;
      this.moonthlygraphlist[m].graph=JSON.parse(JSON.stringify(LineChartdatakarea));

      LineChartdatakareatype.dataTable =this.moonthlygraphlist[m].datatabletype;
      this.moonthlygraphlist[m].graphtype=JSON.parse(JSON.stringify(LineChartdatakareatype));


      m=m+1;
    });


    //console.log(this.moonthlygraphlist)
    
    LineChartdatakarea.options.height=540

    LineChartdatakarea.options.colors =['#85C1E9','#BFC9CA','#73C6B6','#E6B0AA','#ABB2B9']
    LineChartdatakarea.dataTable =datatable;

    this.bubblecharloc=JSON.parse(JSON.stringify(LineChartdatakarea));


    LineChartdatakareatype.options.height=460

    LineChartdatakareatype.options.colors =['#85C1E9','#BFC9CA','#73C6B6','#E6B0AA','#ABB2B9']
    LineChartdatakareatype.dataTable =datatabletype;

    var bubblecharloctype=JSON.parse(JSON.stringify(LineChartdatakareatype));

   
   this.moonthlygraphlist.unshift({month:"Overall",graph:this.bubblecharloc,datatable:[],graphtype:bubblecharloctype,datatabletype:[]});
   //console.log(this.moonthlygraphlist)
   this.selectedgraph=0;

    var Linechart = {
      chartType: 'AreaChart',
      dataTable: [],
      options: {
        options: {
  
          title: "Volume vs Invoice ($)",
          
        },
        // hAxis: {title: 'Volume',textPosition:'none'},
        // vAxis: {title: 'Invoice ($)',textPosition:'none'},
        hAxis: {  gridlines: {
          color: 'transparent',border:"none"

        },textPosition:'none'},
        vAxis :{
          gridlines: {
            color: 'transparent'
          },textPosition:'none'
        },
        pointsize:3,
        
 
        //series: {},
        // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
        
        colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589'],
        // ,
        'chartArea': {'width': '100%', 'height': '100%'},
      height:125
      }
  
    }

    // Linechart.dataTable=[["Month","Carton Count"],["Aug",1693901],	["Sept",1281940],	["Oct",1342884],	["Nov",1511806],	["Dec",1285874],	["Jan",1242652],	["Feb",1309100],	["Mar",1175175],	["Apr",864816]]

    var datatableline= [["Month","Carton Count"]]
    var datatablelinecost= [["Month","Invoice ($)"]]

    this.listtoatlbymonth.forEach(element => {
      
      if(element.totalcarton>0)
      datatableline.push([element.month,element.totalcarton])
      if(element.totalcost>0)
      datatablelinecost.push([element.month,element.totalcost])


    });

    Linechart.dataTable=datatableline;
    this.monthlyvolume=JSON.parse(JSON.stringify(Linechart));
    Linechart.options.colors=['#F5B7B1']

   

    var clist=['#AF7AC5','#16A085','#F4D03F','#E67E22']
    var i=0;
    var ComboChart = {
      chartType: 'ComboChart',
      dataTable: [],
      options: {
        options: {
  
          title: "Volume vs Invoice ($)",
          
        },
        // hAxis: {title: 'Volume',textPosition:'none'},
        // vAxis: {title: 'Invoice ($)',textPosition:'none'},
        hAxis: {  gridlines: {
          color: 'transparent',border:"none"

        },textPosition:'none'},
        vAxis :{
          gridlines: {
            color: 'transparent'
          },textPosition:'none'
        },
        pointsize:3,
        
 
        //series: {},
        series: {0: {type: 'area',targetAxisIndex:0},1: {type: 'bars',targetAxisIndex:1}},
        colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589'],
        // ,
        'chartArea': {'width': '100%', 'height': '100%'},
      height:125
      }
  
    }
    this.costbytype.forEach(type => {

      
      var datatableline=[["Month","Invoice ($)","Carton Count"]]

      type.montlist.forEach(element => {
          
            if(element.totalcarton>0)
              datatableline.push([element.month,element.totalcost,element.totalcarton])
            // if(element.totalcost>0)
            // datatablelinecost.push([element.month,element.totalcost])
      
      
          });
      
          //console.log(datatableline);
          ComboChart.dataTable=datatableline;
          ComboChart.options.colors=[clist[i]]

          var graph=JSON.parse(JSON.stringify(ComboChart));
          type.graph=graph;

       
          i =i +1;
    });


    //console.log(this.costbytype);

    // Linechart.dataTable=[["Month","Carton Count"],["Aug",14301756],	["Sept",10629743],	["Oct",13471262],	["Nov",10775807],	["Dec",9926658],	["Jan",11612199],	["Feb",9889283],	["Mar",9309649],	["Apr",7405005]]
    Linechart.dataTable=datatablelinecost;

    										
    this.monthlycost=JSON.parse(JSON.stringify(Linechart));;
    //console.log(this.monthlyvolume)

    var PieChartbytype = {
      chartType: 'PieChart',
      dataTable: [],
      options: {
        is3D: true,

        options: {
  
          title: "Volume vs Invoice ($)",
          
        },
        hAxis: {title: 'Volume',textPosition:'none'},
        vAxis: {title: 'Invoice ($)',textPosition:'none'},
        // hAxis: {  gridlines: {
        //   color: 'transparent'
        // }},
        // vAxis :{
        //   gridlines: {
        //     color: 'transparent'
        //   }
        // },
        // pointsize:3,
        
        legend: { position: 'none', alignment: 'center' },

        //series: {},
        // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
        
        colors: ['#85C1E9','#73C6B6','#FAD7A0','#CCD1D1','#F5B7B1','#17A589'],
        // ,
        'chartArea': {'width': '90%', 'height': '90%'},

      height:200,
      }
  
    }
    // PieChartbytype.dataTable=[["Type","Carton Count"],["Type1",4043702],["Type2",2277851],["Type3",103622],["Type4",5282973]]

    var datatable=[["Partner","Carton Count"]]
    var datatablecost=[["Partner","Invoice ($)"]]

    this.totabypartner.forEach(element => {

       datatable.push([element.type,element.totalcarton])
       datatablecost.push([element.type,element.totalcost])
      
    });
    PieChartbytype.dataTable=datatable;
    this.piechartvolume=JSON.parse(JSON.stringify(PieChartbytype));
    //console.log(this.piechartvolume)
    PieChartbytype.dataTable=datatablecost;

    // PieChartbytype.dataTable=[["Type","Invoice ($)"],["Type1",20687340],["Type2",23478971],["Type3",6102375],["Type4",47052756]]

    this.piechartcost=JSON.parse(JSON.stringify(PieChartbytype));
    //console.log(this.piechartcost)



    

    this.drawpie();
    
  }
  Guagechart:any;
  functinalsite=''

  guagelist:any=[]
  filteredlist:any=[]
  selectedloc=-1;
  wpartner="";
  drawpie(){

    var PieChartbytype = {
      chartType: 'PieChart',
      dataTable: [],
      options: {
        is3D: true,

        options: {
          pieSliceTextStyle: {
            color: 'black', // Change this to the color you want
          },
          title: "Volume vs Invoice ($)",
          textStyle: {
            color: '#000000', // Customize text color here
            fontSize: 16, // Optional: customize font size
          }
        },
        pieSliceTextStyle: {
          color: 'black', // Change this to the color you want
        },
        hAxis: {title: 'Volume',textPosition:'none'},
        vAxis: {title: 'Invoice ($)',textPosition:'none'},
        // hAxis: {  gridlines: {
        //   color: 'transparent'
        // }},
        // vAxis :{
        //   gridlines: {
        //     color: 'transparent'
        //   }
        // },
        // pointsize:3,
        textStyle: {
          color: '#000000', // Customize text color here
          fontSize: 16, // Optional: customize font size
        },
        legend: { position: 'none', alignment: 'center' },

        //series: {},
        // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
        
        colors: ['#85C1E9','#73C6B6','#FAD7A0','#CCD1D1','#F5B7B1','#17A589'],
        // ,
        'chartArea': {'width': '90%', 'height': '90%'},

      height:200,
      }
  
    }

    var ctx=this;
    console.log(ctx.selectedgraph);
    var sales=JSON.parse(JSON.stringify(this.totalsbylocpartenr));
    console.log(sales)
    var resultpartner:any=[];
    const groupedSales = sales.reduce((acc, current) => {

      // Check if the product is already in the accumulator
      if (!acc[current.partner]) {
        // If not, initialize it with the current amount
        acc[current.partner] = {partner:current.partner,totalcarton:0,totalcost:0};
        resultpartner.push(acc[current['partner']])

      }
      // Add the current amount to the accumulated amount
      if(ctx.selectedgraph>0)
       acc[current.partner].totalcarton += current.monthlist[ctx.selectedgraph-1].totalcarton;
      else
       acc[current.partner].totalcarton += current.totalcarton;

       if(ctx.selectedgraph>0)
       acc[current.partner].totalcost += current.monthlist[ctx.selectedgraph-1].totalcost;
      else
       acc[current.partner].totalcost += current.totalcost;

      return acc; // Return the accumulator for the next iteration
    }, {});
    console.log(groupedSales);

    // Convert the result to an array if needed
    // const result = Object.entries(groupedSales).map(([partner, totalcarton]) => ({
    //   partner,
    //   totalcarton,
    // }));

    var data=[["Partner","Total Cost"]]
    var datac=[["Partner","Total Volume"]]

    resultpartner.forEach(element => {
      data.push([element.partner,element.totalcost])
      datac.push([element.partner,element.totalcarton])

    });
    console.log(resultpartner)
    PieChartbytype.dataTable=data;

    this.piechartcost=JSON.parse(JSON.stringify(PieChartbytype))
    PieChartbytype.dataTable=datac;

    this.piechartvolume=JSON.parse(JSON.stringify(PieChartbytype))

  }
  ming=0;
  maxg=1000;
  gt=0;

  setguage(v){

    if(v==0){
      this.ming=0;
      this.maxg=1000;

    }else if(v==1)
    {
      this.ming=0;
      this.maxg=40;

    }else if(v==2)
    {
      this.ming=41;
      this.maxg=60;
      
    }else if(v==3)
    {
       
      this.ming=61;
      this.maxg=80;
    }

    else if(v==4)
      {
         
        this.ming=81;
        this.maxg=100;
      }
      else if(v==5)
        {
           
          this.ming=101;
          this.maxg=1000;
        }
        console.log(this.ming,this.maxg)
  }

  drawguagegraph(){


    this.guagelist=[]
    this.selectedloc=-1;
    var ctx=this;
    this.gt=0;
    this.setguage(0)
    console.log(this.loctyprebk);
    if(this.functinalsite!='')
    {
      if(this.wpartner!='')
       this.filteredlist=this.loctyprebk.filter(function(item:any){return item.p==ctx.wpartner && item.partner==ctx.functinalsite } ) 
      else
      this.filteredlist=this.loctyprebk.filter(function(item:any){return  item.partner==ctx.functinalsite } ) 

    }
    else{
      if(this.wpartner!='')
        this.filteredlist=this.loctyprebk.filter(function(item:any){return item.p==ctx.wpartner } );
      else
       this.filteredlist=this.loctyprebk.filter(function(item:any){return true } );

   }

    
    this.filteredlist.forEach(element => {

      if(this.selectedgraph>0)
        {
          var monthlist=[];
          element.monthlist.forEach(m => {
            
          
       var Guage = {  
        chartType: 'Gauge',
        dataTable: [['Label', 'Value'],
        
        ['BTS', 55]],
        formatters: [
          {
            columns: [1],
            type: 'NumberFormat',
            options: {
              suffix: '%',
              fractionDigits: 0
            }
          }
        ],
        options: {
          options: {
    
            title: "Volume vs Invoice ($)",
            
          },
         
          legend: 'none',
        
         
   
          //series: {},
          // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
          
          colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589'],
          // ,
          'chartArea': {'width': '100%', 'height': '70%'},
        
        redFrom: 81,
        redColor:'#F1948A',
        redTo: 100,
        yellowFrom: 0,
        yellowColor:'rgb(244, 208, 64)',

        yellowTo: 60,
        greenFrom:61,greenColor:'#7DCEA0',
        greenTo:80,
        minorTicks: 5
        }
    
       }

       //console.log(this.selectedgraph)
       var dataTable =[['Type','Utilization']]
       dataTable.push([m.partner,m.totalcost*100])
  
      if((m.totalcost*100)>100){
          Guage.options.redColor='#B03A2E'

      }
      if((m.totalcost*100)<40){
      Guage.options.yellowColor='#F9E79F'

     }
       Guage.dataTable=dataTable;
       monthlist.push({graph:JSON.parse(JSON.stringify(Guage)),months:m})
      });
          this.guagelist.push({mlist:monthlist,obj:element,loc:element.loc,graph:monthlist[this.selectedgraph-1].graph});
        }
        else{
          var Guage = {
            chartType: 'Gauge',
            dataTable: [['Label', 'Value'],
            
            ['BTS', 55]],
            formatters: [
              {
                columns: [1],
                type: 'NumberFormat',
                options: {
                  suffix: '%',
                  fractionDigits: 0
                }
              }
            ],
     
            options: {
              options: {
        
                title: "Volume vs Invoice ($)",
                
              },
             
              legend: 'none',
      
              
              //series: {},
              // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
              
              colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589'],
              // ,
              'chartArea': {'width': '100%', 'height': '70%'},
            
              redFrom: 81,
              redTo: 100,
               redColor:'#F1948A',
              yellowFrom: 0,
              yellowColor:'rgb(244, 208, 64)',

              yellowTo: 60,
              greenFrom:61,greenColor:'#7DCEA0',
              greenTo:80,
              minorTicks: 5
            }
        
           }
    
           //console.log(this.selectedgraph)
           var dataTable =[['Type','Utilization']]
           dataTable.push([element.partner,parseInt((element.totalcost*100).toLocaleString(this.lc,this.opt))])
           if((element.totalcost*100)>100){
            Guage.options.redColor='#B03A2E'
      
        }
        if((element.totalcost*100)<40){
          Guage.options.yellowColor='#F9E79F'
  
       }
           Guage.dataTable=dataTable;
          
           var monthlist=[];
           element.monthlist.forEach(m => {
             
           
        var Guage = {
         chartType: 'Gauge',
         dataTable: [['Label', 'Value'],
         
         ['BTS', 55]],
         formatters: [
           {
             columns: [1],
             type: 'NumberFormat',
             options: {
               suffix: '%',
               fractionDigits: 0
             }
           }
         ],
         options: {
           options: {
     
             title: "Volume vs Invoice ($)",
             
           },
          
           legend: 'none',
         
          
    
           //series: {},
           // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
           
           colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589'],
           // ,
           'chartArea': {'width': '100%', 'height': '70%'},
         
         redFrom: 81,
         redTo: 100,
         redColor:'#F1948A',

         yellowFrom: 0,
         yellowColor:'rgb(244, 208, 64)',

         yellowTo: 60,
         greenFrom:61,greenColor:'#7DCEA0',
         greenTo:80,
         minorTicks: 5
         }
     
        }
 
        //console.log(this.selectedgraph)
        var dataTable =[['Type','Utilization']]
        dataTable.push([m.partner,m.totalcost*100])
        if((m.totalcost*100)>100){
          Guage.options.redColor='#B03A2E'
    
      }
      if((m.totalcost*100)<40){
        Guage.options.yellowColor='#F9E79F'

     }
        Guage.dataTable=dataTable;
        monthlist.push({graph:JSON.parse(JSON.stringify(Guage)),months:m})
       });
          this.guagelist.push({loc:element.loc,obj:element,graph:JSON.parse(JSON.stringify(Guage)),monthlist:monthlist});
     
        }
        
    });

    console.log(this.guagelist);
    
   
  }
  partnerlist:any=[]
  selectionlist:any=[]
  setmonth(){
    // if(this.selectedloc>-1)
    //   {    
    //     this.selectedgraph=0
    //     this.drawguagegraph();
    //   } 
 }
  getpartnerdata(){
    var ctx=this;

    if(this.filters.partner!='')
     return this.selectionlist.filter(function(item:any){return item.partner==ctx.filters.partner} ) 
    else
     return this.selectionlist;

  }
  get_disticnt_partner(){

    this.partnerlist = this.datalist
       .map((item) => item['Partner'])
       .filter(
           (value, index, current_value) => current_value.indexOf(value) === index
       );

       //console.log(this.partnerlist);
      //  this.filters.partner = this.partnerlist[0];
       this.get_typeby_partner();

       var ctx=this;
      //  this.partnerlist.forEach(partner => {


            

    //   });
       //console.log(this.selectionlist)
  }  

 

  filters = {
    partner:"",
    type:"",
    loc:"Overall"
  }
  typeslist:any=[]

  
  typeby_partner(){

    var ctx=this;
    // this.typeslist = typeslist_a
    // .map((item) => item['Function/Site'])
    // .filter(
    //     (value, index, current_value) => current_value.indexOf(value) === index
    // );
    var typeslist_a:any=[];
    //console.log( this.filters.partner);

    // this.selectionlist=[]
    if(this.wpartner!='')
      {
        typeslist_a = this.datalist.filter(function(item:any){return item.Partner==ctx.wpartner} ) 
      }
    else
    {
      typeslist_a= this.datalist;
      // this.loctyprebk=JSON.parse(JSON.stringify(this.totalsbyloctype))
    }
    

    this.loctyprebk=JSON.parse(JSON.stringify(typeslist_a));
    this.site = this.getDistinctByKey(typeslist_a,'Function/Site')

    this.loctyprebk=this.totalsbyloctype.filter(function(item:any){
      return ctx.site.includes(item.partner) } ) 
    this.functinalsite=''
    this.selectedloc=0

    this.gt=0;
    this.selectedgraph=0;
    this.drawguagegraph();
  }
   getDistinctByKey = (array, key) => {
    return array.reduce((accumulator, current) => {
        const existing = accumulator.find(item => item === current[key]);
        if (!existing) {
            accumulator.push(current['Function/Site']);
        }
        return accumulator;
    }, []);
};
  get_typeby_partner(){

    var ctx=this;
    // this.typeslist = typeslist_a
    // .map((item) => item['Function/Site'])
    // .filter(
    //     (value, index, current_value) => current_value.indexOf(value) === index
    // );
    var typeslist_a:any=[];
    //console.log( this.filters.partner);

    this.selectionlist=[]
    if(this.filters.partner!='')
      typeslist_a = this.datalist.filter(function(item:any){return item.Partner==ctx.filters.partner} ) 
    else
    typeslist_a = this.datalist;
    // var typeslist_a = this.datalist.filter(function(item:any){return item.Partner==partner} ) 
    //console.log( typeslist_a);

    var typeslist =typeslist_a
            .map((item) => item['Function/Site'])
            .filter(
                (value, index, current_value) => current_value.indexOf(value) === index
            );

            var typedata=[]
            //console.log(typeslist);
            typeslist.forEach(type => {

              
              
              var loc_a = typeslist_a.filter(function(item:any){

                return item['Function/Site']==type} ) 
              //console.log(loc_a)
              var loc = loc_a
              .map((item) => item['Location'])
              .filter(
                  (value, index, current_value) => current_value.indexOf(value) === index
              );
              this.selectionlist.push({type:type,loc:loc})
              // this.selectionlist.push({type:type,loc:typedata})

            });


   //console.log(this.selectionlist)
   this.select('','Overall')
   // this.filters.type = this.typeslist[0]
   //this.getlocby_partnerand_type();
  }
  loc:any=[]
  volumechart:any;
  costchart:any;
  costchart_seg:any;

  getlocby_partnerand_type(){
    var ctx=this;

    var loc_a = this.datalist.filter(function(item:any){return item.Partner==ctx.filters.partner && item['Function/Site']==ctx.filters.type} ) 
    this.loc = loc_a
    .map((item) => item['Location'])
    .filter(
        (value, index, current_value) => current_value.indexOf(value) === index
    );
    this.loc.unshift('Overall')
  //  this.filters.loc='Overall';
   // this.filter_data()
   this.filter_data();
  }

  filterdata:any=[]
  selectedtab=3;
  
  select(type,loc){
    this.filters.loc=loc;
    this.filters.type=type;
    this.filter_data()

  }

  totalspace=0;
  totalpallets=0;
  startdate:'';
  enddate:'';
  anuual:'';
  totalinvoice=0;
  notes:''
  totalvolume=0;
  utilizationchart:any;
  utilizationchartsmall:any;
  getrange(f){

    var month=JSON.parse(JSON.stringify(this.monlthgraphs)).reverse();
    console.log(month);
    for(var i=0,j=11;i<month.length;i++,j--)
      {
        var element =month[i];

       if(element.totalcarton!=0)
        {
          this.volumerange=this.month[j];


          break;
        }
          

    }
    for(var i=0,j=11;i<month.length;i++,j--)
      {
        var element =month[i];
        if(element.totalcost!=0)
          {
          this.invoicerange=this.month[j];;

          break;
        }
          

    }
    
    for(var i=0,j=11;i<month.length;i++,j--)
      {


        var element =month[i];

        console.log(i,element)
        if(element.utilization!=0)
          {

            this.utilizationrange=this.month[j];

            break;
          }
          


    }
    console.log(this.invoicerange,this.volumerange,this.utilizationrange);

    if(f)
      {
        this.range=this.invoicerange;
        this.rangev=this.volumerange;
        this.rangeu=this.utilizationrange;

      }
  }
  range=""
  rangev=""
  rangeu=""
  totalspaced=0;
  utlizationd=0;
  seg_flag=false;
  rate_chart:any;
  rate_Flag=0;
  rate_chart_large:any;
  cost_chart_compair:any=[]
  volume_Chart_compair:any=[]
  rate_comapir:any=[]
  spend_cumilative:any;

  get_data(){
    if(this.selectedfiscal>0)
    var list=  JSON.parse(this.warehousedatalist[this.selectedfiscal-1].data);
    else
    list=[]

    var keymap={
      "Partner": "Partner",
      "Function/Site": "Function/Site",
      "Location": "Location",
      "Region": "Region",
      "Regioncode": "Regioncode",
      "Annual Spend FY24": "Annual Spend FY24",
      "Space": "Space",
      "Sq ft": "Sq ft",
      "Pallets": "Pallets",
      "Volume For Q1": "Q1FY24",
      "Volume For August": "August",
      "Volume For September": "September",
      "Volume For October": "October",
      "Volume For Q2": "Q2FY24",
      "Volume For November": "November",
      "Volume For December": "December",
      "Volume For January": "January",
      "Volume For Q3": "Q3FY24",
      "Volume For February": "February",
      "Volume For March": "March",
      "Volume For April": "April",
      "Volume For Q4": "Q4FY24",
      "Volume For May": "May",
      "Volume For June": "June",
      "Volume For July": "July",
      "Invoice Spend For Q1": "Q1FY24.1",
      "Invoice Spend For August": "August.1",
      "Invoice Spend For September": "September.1",
      "Invoice Spend For October": "October.1",
      "Invoice Spend For Q2": "Q2FY24.1",
      "Invoice Spend For November": "November.1",
      "Invoice Spend For December": "December.1",
      "Invoice Spend For January": "January.1",
      "Invoice Spend For Q3": "Q3FY24.1",
      "Invoice Spend For February": "February.1",
      "Invoice Spend For March": "March.1",
      "Invoice Spend For April": "April.1",
      "Invoice Spend For Q4": "Q4FY24.1",
      "Invoice Spend For May": "May.1",
      "Invoice Spend For June": "June.1",
      "Invoice Spend For July": "July.1",
      "Start Date": "Start Date",
      "End Date": "End Date",
      "notes": "notes",
      "Annual Increase": "Annual Increase",
      "Site Utilization For August": "August.2",
      "Site Utilization For September": "September.2",
      "Site Utilization For October": "October.2",
      "Site Utilization For November": "November.2",
      "Site Utilization For December": "December.2",
      "Site Utilization For January": "January.2",
      "Site Utilization For February": "February.2",
      "Site Utilization For March": "March.2",
      "Site Utilization For April": "April.2",
      "Site Utilization For May": "May.2",
      "Site Utilization For June": "June.2",
      "Site Utilization For July": "July.2",
      "Peak Utilization For August": "August.3",
      "Peak Utilization For September": "September.3",
      "Peak Utilization For October": "October.3",
      "Peak Utilization For November": "November.3",
      "Peak Utilization For December": "December.3",
      "Peak Utilization For January": "January.3",
      "Peak Utilization For February": "February.3",
      "Peak Utilization For March": "March.3",
      "Peak Utilization For April": "April.3",
      "Peak Utilization For May": "May.3",
      "Peak Utilization For June": "June.3",
      "Peak Utilization For July": "July.3"
    }


    var ctx=this;

    var datalist= this.renameKeys(list, keymap);

    
    var filterdata:any=[]
    
    if(this.filters.partner=='')
      {
    
      if(this.filters.type!='')
        {
          if(this.filters.loc!='Overall')
           filterdata = datalist.filter(function(item:any){return item['Function/Site']==ctx.filters.type && item['Location']==ctx.filters.loc} ) 
          else
          filterdata = datalist.filter(function(item:any){return item['Function/Site']==ctx.filters.type} ) 
        }
      else{
          if(this.filters.loc!='Overall')
            filterdata = datalist.filter(function(item:any){return item['Location']==ctx.filters.loc} ) 
          else
            filterdata = JSON.parse(JSON.stringify(datalist));
        }

    }else
    {

      if(this.filters.type!='')
        {
          if(this.filters.loc!='Overall')
            filterdata = datalist.filter(function(item:any){return item.Partner==ctx.filters.partner && item['Function/Site']==ctx.filters.type && item['Location']==ctx.filters.loc} ) 
          else
            filterdata = datalist.filter(function(item:any){return item.Partner==ctx.filters.partner && item['Function/Site']==ctx.filters.type} ) 
       }
       else{
        if(this.filters.loc!='Overall')
          filterdata = datalist.filter(function(item:any){return item.Partner==ctx.filters.partner && item['Location']==ctx.filters.loc} ) 
        else
          filterdata = datalist.filter(function(item:any){return item.Partner==ctx.filters.partner } )
       }
    }



    return filterdata;
  }
  gethtmltooltip(e,m)
  {
    // 2,3,4,1,0

    var text="<html><body style='maring:10px'><h6 style='margin:4px'>"+m+"</h6><p style='margin:4px'>Invoice ($k) : "+(e[5][m]/1000).toLocaleString(this.lc,this.opt1)+"</p><p style='margin:4px'>Postponement($k) : "+(e[2][m]/1000).toLocaleString(this.lc,this.opt1)+"</p><p style='margin:4px'>Indirect Labor + Fixed fees($k) : "+(e[3][m]/1000).toLocaleString(this.lc,this.opt1)+"</p><p style='margin:4px'>Storage + Fixed fees($k) : "+(e[4][m]/1000).toLocaleString(this.lc,this.opt1)+"</p><p style='margin:4px'>Shipping($k) : "+(e[1][m]/1000).toLocaleString(this.lc,this.opt1)+"</p><p style='margin:4px'>Receiving($k) : "+(e[0][m]/1000).toLocaleString(this.lc,this.opt1)+"</p></body></html>"

    return text;
    // "Postponement($)","Indirect Labor + Fixed fees($)","Storage + Fixed fees($)","Shipping($)","Receiving($)"
  }
  
  filter_data(){ 
    var ctx=this;
    this.seg_flag=false;
    this.rate_Flag=0;


      if(this.filters.partner=='')
      {
        if(this.filters.type!='')
          {
            if(this.filters.loc!='Overall')
              this.filterdata = this.datalist.filter(function(item:any){return item['Function/Site']==ctx.filters.type && item['Location']==ctx.filters.loc} ) 
            else
              this.filterdata = this.datalist.filter(function(item:any){return item['Function/Site']==ctx.filters.type} ) 
          }
        else{
            if(this.filters.loc!='Overall')
              this.filterdata = this.datalist.filter(function(item:any){return item['Location']==ctx.filters.loc} ) 
            else
              this.filterdata = JSON.parse(JSON.stringify(this.datalist));
          }

      }else
      {

        if(this.filters.type!='')
          {
            if(this.filters.loc!='Overall')
              this.filterdata = this.datalist.filter(function(item:any){return item.Partner==ctx.filters.partner && item['Function/Site']==ctx.filters.type && item['Location']==ctx.filters.loc} ) 
            else
              this.filterdata = this.datalist.filter(function(item:any){return item.Partner==ctx.filters.partner && item['Function/Site']==ctx.filters.type} ) 
         }
         else{
          if(this.filters.loc!='Overall')
            this.filterdata = this.datalist.filter(function(item:any){return item.Partner==ctx.filters.partner && item['Location']==ctx.filters.loc} ) 
          else
            this.filterdata = this.datalist.filter(function(item:any){return item.Partner==ctx.filters.partner } )
         }
      }

     //console.log(this.filterdata)
     var resultp=[]
    
    
    this.monlthgraphs=[]
    this.listmonth.forEach(element => {

      this.monlthgraphs.push({month:element,totalcarton:0,totalcost:0,space:0,pallets:0,utilization:0,utcnt:0})



      
    });

    
    this.totalpallets=0;
    if(this.filters.partner==''&&this.filters.type=='') 
     this.totalspaced=0
    

    this.filterdata.forEach(value => {
      
      var utc=0;
      this.monlthgraphs.forEach(el => { 

        var m=0,c=0,sp=0,pl=0,ut=0;
        if(value[el.month]!=null)
          m= parseFloat(value[el.month]+"")

        if(value['Sq ft']!=null)
          sp= value['Sq ft']
        if(value['Pallets']!=null)
          pl= value['Pallets']

        if(value[el.month+'.1']!=null)
          c= parseFloat(value[el.month+'.1']+"")

        if(value[el.month+'.2']!=null&&value[el.month+'.2']!=''&&value[el.month+'.2']!=0)
        {
          ut= parseFloat(value[el.month+'.2']+"")
          el.utcnt++;

        }          




        el.totalcost = el.totalcost +c; 
        el.totalcarton = el.totalcarton +m; 
        console.log(el.utilization,ut,utc)
        el.utilization=el.utilization+((ut))

        el.space = el.space + sp; 
        el.pallets = el.pallets + pl; 



      });

      var sp=0,pl=0;
      if(value['Sq ft']!=null)
        sp= value['Sq ft']
      if(value['Pallets']!=null)
        pl= value['Pallets']

      if(this.filters.partner==''&&this.filters.type=='')
         this.totalspaced=this.totalspaced+ sp;

      this.totalspace=this.totalspace+ sp;
      this.totalpallets= this.totalpallets+ pl;
      
      this.startdate= value['Start Date']
      this.enddate= value['End Date'] 
      this.anuual=value['Annual Increase']
      this.notes=value['notes']

     });

    
     this.monlthgraphs.forEach(el => { 

      el.utilization=(el.utilization/el.utcnt)*100
     });
     if(this.filters.partner==''&&this.filters.type=='') 
      this.totalspace=this.totalspaced;
     console.log(this.totalspace,this.totalspaced);
    if(this.filters.partner==''&&this.filters.type=='')
      this.getrange(true);
    else
     this.getrange(false);

    
     var Linechart = {
      chartType: 'LineChart',
      dataTable: [],
      options: {
        options: {
  
          title: "Volume vs Invoice ($)",
          
        },
        annotations: {
          textStyle: {
            fontSize: 12,
            
            color: '#000000'
          }},
        hAxis: {title: 'Month'},
        vAxis: {title: 'Invoice ($)',textPosition:'none',

          gridlines: {
            count: -1 // Set to -1 to disable automatic gridlines
          },
          // ticks: [0,10000,20000,30000,40000,50000]
        },
        
        // hAxis: {  gridlines: {
        //   color: 'transparent'

        // }},
        legend:{ position: 'top', alignment: 'center' },

        // vAxis :{
        //   gridlines: {
        //     color: 'transparent'
        //   },textPosition:'none'
        // },
        pointsize:3,
        
 
        //series: {},
        // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
        
        colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589'],
        // ,
        'chartArea': {'width': '80%', 'height': '70%'},
      height:220
      }
  
    }
    var ComboChart = {
      chartType: 'ComboChart',  // Set the chart type to ComboChart
      dataTable: [],
      options: {
        title: "Volume vs Invoice ($)",
    
        annotations: {
          textStyle: {
            fontSize: 12,
            color: '#000000',
          }
        },
        hAxis: {
          title: 'Month',
        },
        vAxis: {
          title: 'Invoice ($)',
          textPosition: 'none',
          gridlines: {
            count: -1, // Set to -1 to disable automatic gridlines
          },
        },
        legend: {
          position: 'top',
          alignment: 'center',
        },
        pointSize: 3,
        colors: ['#d7bde2', '#16A085', '#ba4a00'],
        chartArea: {
          width: '80%',
          height: '70%',
        },
        height: 220,
    
        // Series configuration to define chart types
        series: {
          0: {
            type: 'area',   // Area chart for the first series
            targetAxisIndex: 0,
          },
          1: {
            type: 'line',   // Line chart for the second series
            targetAxisIndex: 0,
          },
          2: {
            type: 'line',   // Line chart for the third series
            targetAxisIndex: 0,
          },
        },
      },
    };

    var Linechart1 = {
      chartType: 'LineChart',
      dataTable: [],
      options: {
        options: {
  
          title: "Volume vs Invoice ($)",
          
        },
        annotations: {
          textStyle: {
            fontSize: 12,
            
            color: '#000000'
          }},
        hAxis: {title: 'Month'},
        vAxis: {title: 'Volume (Outbound Cartons (K))',textPosition:'none',
          gridlines: {
            count: -1 // Set to -1 to disable automatic gridlines
          },
          // ticks: [0,500,1000,1500,2000,2500,3000,3500,4000,4500,5000]

        },
        // hAxis: {  gridlines: {
        //   color: 'transparent'

        // }},
        legend: { position: 'top', alignment: 'center' },

        // vAxis :{
        //   gridlines: {
        //     color: 'transparent'
        //   },textPosition:'none'
        // },
        pointsize:3,
        
 
        //series: {},
        // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
        
        colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589'],
        // ,
        'chartArea': {'width': '80%', 'height': '70%'},
      height:220
      }
  
    }

    var barchart = {
      chartType: 'ColumnChart',
      dataTable: [],
      options: {
        options: {
  
          title: "Volume vs Invoice ($)",
          
        },
        annotations: {
          alwaysOutside:false,
          textStyle: {
            fontSize:9,
            
            color: '#000000'
          }},
        
        // hAxis: {title: 'Volume',textPosition:'none'},
        // vAxis: {title: 'Invoice ($)',textPosition:'none'},
        // hAxis: {  gridlines: {
        //   color: 'transparent'

        // }},
        legend: 'none',

        vAxis :{
          gridlines: {
            color: 'transparent'
          },textPosition:'none'
        },
        pointsize:3,
        
 
        //series: {},
        // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
        
        colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589'],
        // ,
        'chartArea': {'width': '88%', 'height': '70%'},
      height:220
      }
  
    }

    var benchmark_combined_chart_spend = {
      chartType: 'ComboChart',
      dataTable: [ /* Ensure this contains your combined data */ ],
      options: {
        bar: { groupWidth: '40%' },
        chartArea: { width: '90%', height: '60%' },
        vAxes: {
          0: {
            gridlines: { color: 'transparent' },
            textPosition: 'none'
            
          },
          1: {
            gridlines: { color: 'transparent' },
            textPosition: 'none'
            
          }
        },
        legend: {
          position: 'top',
          alignment: 'center',
          textStyle: {
            fontSize: 8 // Change this to your desired font size
          },
          maxLines: 2 // Set to a high value to avoid wrapping
        },
        series: {
          0: { type: 'line', targetAxisIndex: 1 }, // Line series
          1: { type: 'line', targetAxisIndex: 2}, // Line series

          2: { type: 'bars', targetAxisIndex: 0, isStacked: true }, // Stacked bar series
          3: { type: 'bars', targetAxisIndex: 0, isStacked: true }, // Stacked bar series
          4: { type: 'bars', targetAxisIndex: 0, isStacked: true }, // Stacked bar series
          5: { type: 'bars', targetAxisIndex: 0, isStacked: true }, // Stacked bar series
          6: { type: 'bars', targetAxisIndex: 0, isStacked: true }, // Stacked bar series
        },
        tooltip: { isHtml: true },

        isStacked: true,
 
        hAxis: {
          gridlines: { color: 'transparent' }
        },
        
        height: 220,
        pointSize: 2,
        colors:[
          '#F5B7B1',  // Original color
          "#000000",
            // Light green
          '#d7ce7d',  // Light yellow-green
          '#bfc9ca',
          '#a569bd', 
          '#7dd7a3',
          '#7db8ce',  // Light teal
           // Light rose
          '#7d7dce',  // Light blue
          '#d78f7a' // Light coral
        ]
      }
    };

    // Linechart.dataTable=[["Month","Carton Count"],["Aug",1693901],	["Sept",1281940],	["Oct",1342884],	["Nov",1511806],	["Dec",1285874],	["Jan",1242652],	["Feb",1309100],	["Mar",1175175],	["Apr",864816]]

    var datatableline_c= [["Month","Carton Count (k) FY24","Carton Count (k) FY25"]]

    var datatableline= [["Month","Carton Count (k)",{role:'annotation'}]]

    if(this.filters.type=='BTS')
      {
        var datatableline= [["Month","Unit (k)",{role:'annotation'}]]

      }

    var datatablerate=[["Month","All-inclusive cost per carton($)",{role:'annotation'}]]
    var datatablerate_comaire=[["Month","All-inclusive cost per carton($) 24",{role:'annotation'},"All-inclusive cost per carton($) 25",{role:'annotation'}]]

    if(this.filters.type=='BTS')
    {
      datatablerate=[["Month","All-inclusive cost per unit($)",{role:'annotation'}]]
      datatablerate_comaire=[["Month","All-inclusive cost per unit($) 24",{role:'annotation'},"All-inclusive cost per unit($) 25",{role:'annotation'}]]
    }

    var datatablelinecost= [["Month","Invoice ($)",{role:'annotation'}]]
    var datatablelinecost_c= [["Month","Invoice ($) FY24","Invoice ($) FY25"]]

    var datatablelinecost_cum= [["Month","Invoice ($) FY24 Total Spend","Invoice ($) FY24","Invoice ($) FY25"]]

    var datatablelinecost_seg= [["Month","Invoice ($)",{ role: 'tooltip' ,p: { html: true } },"Cost per Unit($)","Postponement($)","Indirect Labor + Fixed fees($)","Storage + Fixed fees($)","Shipping($)","Receiving($)",{role:'annotation'}]]

    var datatablelutilization= [["Month","Utilization (%)",{role:'style'},{role:'annotation'}]]

    if(this.filters.partner==''&&this.filters.type=='')
      {
      this.totalinvoice=0;
      this.totalvolume=0;
      this.utlizationd=0;

      //  this.totalspaced=0;
      }
    
      // this.totalspace=0;

    var m=0;
    var cnt=0
    var data_seg:any=[]

    if(ctx.filters.partner=='')
     data_seg=this.segmentation.filter(res=> {return res.Type==ctx.filters.type  && res.Lane==ctx.filters.loc})
    else
     data_seg=[]
    console.log(this.monlthgraphs);

    var datalist=this.get_data();
    console.log(this.datalist);



   
    

    var totalspend:any=0;
    var totalspend_pre:any=0;

    var k=-1;
    var divider=0;
    this.monlthgraphs.forEach(element => {

      k++;
      var data:any;

      var cost=0,volume=0;
      datalist.forEach(dt => {

          if(dt[element.month]!=null)
            volume=volume+dt[element.month];

          if(dt[element.month+".1"]!=null)
            cost=cost+dt[element.month+".1"];
            
      });

      // var cspend=0;
      totalspend=totalspend+(cost/1000);
      var rate:any=null;
      if(cost!=0)
      {
        rate=cost/volume;
      }
      data={"month":element.month,"cost":cost,"volume":volume,"rate":rate,"cspend":totalspend}
      console.log(element) 
      var v=undefined;
      if(element.totalcarton>0)
        v=element.totalcarton/1000;
 
      if(v>0){
        
        datatableline.push([this.month[m],v,v.toLocaleString(this.lc,this.opt)])
         datatableline_c.push([this.month[m],data.volume/1000,v])
        
    
      }
      else 
      {
        datatableline.push([this.month[m],v,"-"])
       
          datatableline_c.push([this.month[m],data.volume/1000,v])
       


      }


         var c=undefined;
         if(element.totalcost>0)
           c=element.totalcost/1000;

          
           
           
          if(c>0)
            {         
              var avg:any=c/v;
              totalspend_pre=totalspend_pre+c;

               
                datatablelinecost.push([this.month[m],c,(c).toLocaleString(this.lc,this.opt)])
                datatablerate.push([this.month[m],avg,(avg).toLocaleString(this.lc,this.opt1)])

                datatablelinecost_c.push([this.month[m],data.cost/1000,c])


                 datatablelinecost_cum.push([this.month[m],totalspend,data.cspend,totalspend_pre])
              


                var ant="-"
                var ant1="0"

                if(avg==undefined||Number.isNaN(avg)==true)
                  {
                    ant="-"
                    console.log("s")
                  } 
                  else{
                   
                    if(k>0)
                      {
                          ant="$"+(avg-parseFloat(datatablerate_comaire[k][3]+"")).toLocaleString(this.lc,this.opt1);
                          // ant1="$"+(data.rate-parseFloat(datatablerate_comaire[k][1]+"")).toLocaleString(this.lc,this.opt1);
  
                      }
                  }

                  if(data.rate!=undefined&&data.rate!=null&&Number.isNaN(data.rate)==false)
                    {
                      if(k>0)
                        {
                            // ant="$"+(avg-parseFloat(datatablerate_comaire[k+1][2]+"")).toLocaleString(this.lc,this.opt1);
                            ant1="$"+(data.rate-parseFloat(datatablerate_comaire[k][1]+"")).toLocaleString(this.lc,this.opt1);
    
                        }
                    }
                    console.log(ant+"s"+avg);

                datatablerate_comaire.push([this.month[m],data.rate,ant1,avg,ant])
                
                if(data_seg.length>0)
                {
                  datatablelinecost_seg.push([this.month[m],data_seg[5][element.month]/1000,this.gethtmltooltip(data_seg,element.month),data_seg[7][element.month],data_seg[2][element.month]/1000,data_seg[3][element.month]/1000,data_seg[4][element.month]/1000,data_seg[1][element.month]/1000,data_seg[0][element.month]/1000,(data_seg[5][element.month]/1000).toLocaleString(this.lc,this.opt)])

                }
            }          
            else
            {
                 var avg:any=c/v;

              datatablelinecost.push([this.month[m],c,"-"])
              datatablelinecost_c.push([this.month[m],data.cost/1000,c])
              
               datatablelinecost_cum.push([this.month[m],totalspend,data.cspend,null])

              datatablerate.push([this.month[m],avg,"-"])
              var ant=""
                var ant1="0"

              if(avg==undefined||Number.isNaN(avg)==true)
                {
                  ant="-"
                  console.log("s")
                }
                else{
                 
                  if(k>0)
                    {
                        ant="$"+(avg-parseFloat(datatablerate_comaire[k][3]+"")).toLocaleString(this.lc,this.opt1);
                        // ant1="$"+(data.rate-parseFloat(datatablerate_comaire[k][1]+"")).toLocaleString(this.lc,this.opt1);

                    }
                }

                if(data.rate!=undefined&&data.rate!=null&&Number.isNaN(data.rate)==false)
                  {
                    if(k>0)
                      {
                          // ant="$"+(avg-parseFloat(datatablerate_comaire[k+1][2]+"")).toLocaleString(this.lc,this.opt1);
                          ant1="$"+(data.rate-parseFloat(datatablerate_comaire[k][1]+"")).toLocaleString(this.lc,this.opt1);
  
                      }
                  }
                  console.log(ant+"s"+avg);

              datatablerate_comaire.push([this.month[m],data.rate,ant1,avg,ant])

              console.log(datatablerate_comaire);
              // console.log(this.month[m],data_seg[5][element.month]/1000,data_seg[7][element.month],data_seg[0][element.month]/1000,data_seg[1][element.month]/1000,data_seg[2][element.month]/1000,data_seg[3][element.month]/1000,data_seg[4][element.month]/1000)
              if(data_seg.length>0)
                {
                  
                    
                  datatablelinecost_seg.push([this.month[m],data_seg[5][element.month]/1000,this.gethtmltooltip(data_seg,element.month),data_seg[7][element.month],data_seg[2][element.month]/1000,data_seg[3][element.month]/1000,data_seg[4][element.month]/1000,data_seg[1][element.month]/1000,data_seg[0][element.month]/1000,'-'])

                }
            }
       
      
          var fcolor='#F5A9A9';
          var bcolor='#F7F2E0';
          if(element.utilization<=60)
            {
               fcolor='#F4D03F';
               bcolor='#FCF3CF';
            }
            else if (element.utilization>60&&element.utilization<81){
               fcolor='#7DCEA0';
               bcolor='#D5F5E3';
            }
            else if (element.utilization>100){
              fcolor='#B03A2E';
              bcolor='#D5F5E3';
           }

          
            
            var f=0,u=element.utilization;
            if((element.utilization)<100)
               f=100-element.utilization;
            //  u=100;

            if(this.filters.partner==''&&this.filters.type=='')
               {
                // console.log(element.utilization,Number.isNaN(element.utilization),Number.isNaN(element.utilization)&&element.utilization>0);
                if(Number.isNaN(element.utilization)==false&&element.utilization>0)
                { 
                  
                  divider=divider+1;
                  this.utlizationd=this.utlizationd+element.utilization;

                }
                 
               }
               console.log(Number.isNaN(element.utilization));
               if(Number.isNaN(element.utilization)==false)
                datatablelutilization.push([this.month[m],u,fcolor,element.utilization.toLocaleString(this.lc,this.opt)+""])
               else
               datatablelutilization.push([this.month[m],null,fcolor,"-%"])
 
         

      //  if(cnt<=0)
      //   datatablelutilization= [["Month","Utilization (%)",{role:'annotation'}]];

          
      if(this.filters.partner==''&&this.filters.type=='')
        {
        this.totalinvoice=this.totalinvoice+element.totalcost;
        this.totalvolume=this.totalvolume + element.totalcarton;
        // this.totalspaced=this.totalspaced + element.space;
        }
        // this.totalspace=this.totalspace + element.space;

       m=m+1;
      

      });

      console.log( this.utlizationd,divider);
    if(this.filters.partner==''&&this.filters.type=='')
      this.utlizationd=this.utlizationd/divider

    var c=0;
    datatableline.slice(1,datatableline.length-1).forEach(element => {
      
      //console.log(element[1]);
       if(element[1]!=undefined)
         c=c+1;

    });

   
    if(c==0)
      {

        datatableline=[["Month","Carton Count (k)",{role:'annotation'}]];

        if(this.filters.type=='BTS')
          {
             datatableline= [["Month","Unit (k)",{role:'annotation'}]]
    
          }
      }

       c=0;
      datatablelinecost_c.slice(1,datatablelinecost_c.length-1).forEach(element => {
      
        //console.log(element[1]);
         if(element[1]!=undefined)
           c=c+1;
  
      });
  
     
      if(c==0)
        {
          datatablelinecost_c= [["Month","Invoice ($) FY24","Invoice ($) FY25"]]
        }

        c=0;
        datatablelinecost.slice(1,datatablelinecost.length-1).forEach(element => {
        
          //console.log(element[1]);
           if(element[1]!=undefined)
             c=c+1;
    
        });
    
       
        if(c==0)
          {
             datatablelinecost= [["Month","Invoice ($) FY24","Invoice ($) FY25"]]
          }

          c=0;
          datatablelutilization.slice(1,datatablelutilization.length-1).forEach(element => {
          
            //console.log(element[1]);
             if(element[1]!=null)
               c=c+1;
      
          });
      
         
          if(c==0)
            {
              datatablelutilization= [["Month","Utilization (%)",{role:'style'},{role:'annotation'}]]
            }
        //c=0;
      // datatablelinecost_c.slice(1,datatablelinecost_c.length-1).forEach(element => {
      
      //   //console.log(element[1]);
      //    if(element[1]!=undefined)
      //      c=c+1;
  
      // });
  
     
      // if(c==0)
      //   {
      //     var datatablelinecost_c= [["Month","Invoice ($) FY24","Invoice ($) FY25"]]
      //   }



      for(var i=1;i<datatablelinecost_cum.length;i++)
        {
          datatablelinecost_cum[i][1]=totalspend;
        }
            var v:any=0;

        //console.log(c)
      console.log(datatablelinecost_cum)
    Linechart1.dataTable=datatableline;
    this.volumechart=JSON.parse(JSON.stringify(Linechart1));

    if(datatableline_c[1][1]==undefined||datatableline_c[1][1]==null||Number.isNaN(parseFloat(datatableline_c[1][1])))
      datatableline_c[1][1]=v;

    if(datatableline_c[1][2]==undefined||datatableline_c[1][2]==null||Number.isNaN(parseFloat(datatableline_c[1][2])))
      datatableline_c[1][2]=v;

    Linechart1.dataTable=datatableline_c;
    Linechart1.chartType='ColumnChart'
    Linechart1.options.vAxis.textPosition='center'

    this.volume_Chart_compair=JSON.parse(JSON.stringify(Linechart1));
    Linechart1.options.vAxis.textPosition='none'

    Linechart1.chartType='LineChart'

    console.log(datatableline_c)

    Linechart.dataTable=datatablelinecost;
    Linechart.options.colors=['#F5B7B1']
    Linechart.options.height=200

    this.costchart=JSON.parse(JSON.stringify(Linechart));



    if(datatablelinecost_c[1][1]==undefined||datatablelinecost_c[1][1]==null||Number.isNaN(parseFloat(datatablelinecost_c[1][1])))
      datatablelinecost_c[1][1]=v;

    if(datatablelinecost_c[1][2]==undefined||datatablelinecost_c[1][2]==null||Number.isNaN(parseFloat(datatablelinecost_c[1][2])))
      datatablelinecost_c[1][2]=v;

    Linechart.dataTable=datatablelinecost_c;
    Linechart.options.colors=['#F5B7B1','#cd6155']
    Linechart.options.height=200
    Linechart.chartType='ColumnChart'
    Linechart.options.vAxis.textPosition='center'

    this.cost_chart_compair=JSON.parse(JSON.stringify(Linechart));
    Linechart.chartType='LineChart'
    Linechart.options.vAxis.textPosition='none'


    Linechart.dataTable=datatablerate;
    Linechart.options.colors=['#F5B7B1']
    Linechart.options.options.title='Cost per Carton ($)'
    Linechart.options.vAxis.title='Cost per Carton($)'

    Linechart.options.height=200

    this.rate_chart=JSON.parse(JSON.stringify(Linechart));

    if(datatablerate_comaire[1][1]==null||Number.isNaN(parseFloat(datatablerate_comaire[1][1]+"")))
      datatablerate_comaire[1][1]=v;
  
    if(datatablerate_comaire[1][3]==null||Number.isNaN(parseFloat(datatablerate_comaire[1][3]+"")))
      datatablerate_comaire[1][3]=v;
  

    
    
    Linechart.dataTable=datatablerate_comaire;
    Linechart.options.colors=['#F5B7B1','#85c1e9']
    Linechart.options.options.title='Cost per Carton ($)'
    Linechart.options.vAxis.title='Cost per Carton($)'
    Linechart.options.vAxis.textPosition='center'

    

    Linechart.chartType="AreaChart"
    Linechart.options.height=200

    this.rate_comapir=JSON.parse(JSON.stringify(Linechart));
    Linechart.chartType="LineChart"
    Linechart.options.vAxis.textPosition='none'

    if(datatablelinecost_cum[1][1]==null||Number.isNaN(parseFloat(datatablelinecost_cum[1][1])))
      datatablelinecost_cum[1][1]=v;

    if(datatablelinecost_cum[1][2]==null||Number.isNaN(parseFloat(datatablelinecost_cum[1][2])))
      datatablelinecost_cum[1][2]=v;

    if(datatablelinecost_cum[1][3]==null||Number.isNaN(parseFloat(datatablelinecost_cum[1][3])))
      datatablelinecost_cum[1][3]=v;
    console.log(datatablelinecost_cum)

  ComboChart.dataTable=datatablelinecost_cum;

  this.spend_cumilative=JSON.parse(JSON.stringify(ComboChart));
    Linechart.options.height=280

    this.rate_chart_large=JSON.parse(JSON.stringify(Linechart));



    console.log(datatablelinecost_seg)
    
    benchmark_combined_chart_spend.dataTable=datatablelinecost_seg;
    // benchmark_combined_chart_spend.options.colors=['#F5B7B1']
    this.costchart_seg=JSON.parse(JSON.stringify(benchmark_combined_chart_spend));



    barchart.dataTable=datatablelutilization;
    barchart.options.colors=['#41B856','#C1DEBE']
    console.log(datatablelutilization)

    this.utilizationchart=JSON.parse(JSON.stringify(barchart));
    barchart.options.height=140
    barchart.options.chartArea.height="60%"

    if(this.filters.partner==''&&this.filters.type=='')
     this.utilizationchartsmall=JSON.parse(JSON.stringify(barchart));

    //console.log(datatablelutilization)

  }

  year_line_cost_graph_small:any;

  year_line_cost_graph:any;
  year_line_volume_graph_small:any
  year_line_volume_graph:any;
  year_line_volume_graph_ssmall:any
  year_line_cost_graph_ssmall:any;

  yearutlization:any;
  Geo:any;
  Geo_voulme:any;
  Geo_cost:any;
  locflist:any=[];
  selectedgeo=0;

  graphlist:any=[];
  partnarvolumepiechart:any;
  partnarcostpiechart:any;
  
  dashboardgraphs(){

    console.log(this.totalsbytypes)
    var Linechart = {
      chartType: 'LineChart',
      dataTable: [],
      options: {
        options: {
  
          title: "Volume vs Invoice ($)",
          
        },
        legend: 'none',

        // hAxis: {title: 'Volume',textPosition:'none'},
        // vAxis: {title: 'Invoice ($)',textPosition:'none'},
        hAxis: {
          baselineColor: 'transparent',baselineWidth:0,  gridlines: {
          color: 'none',
          

        },textPosition:'none'},
        vAxis :{
          gridlines: {
            color: 'transparent'
          },textPosition:'none'
        },
        pointsize:3,
        
 
        //series: {},
        // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
        
        colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589'],
        // ,
        'chartArea': {'width': '100%', 'height': '100%'},
      height:80
      }
  
    }


    var datatableline= [["Month","Carton Count (k)"]]
    var datatablelinecost= [["Month","Invoice ($)"]]
    var datatablelutilization= [["Month","Utilization (%)",{role:'style'},"Max(%)",{role:'style'},{role:'annotation'}]]

    this.totalinvoice=0;
    this.totalvolume=0;
    
    var m=0;
    var cnt=0

    this.monlthgraphs.forEach(element => {
      
      var v=undefined;
      if(element.totalcarton>0)
        v=element.totalcarton/1000;

       if(v>0)
         datatableline.push([this.month[m],v])
      else 
      datatableline.push([this.month[m],v])


         var c=undefined;
         if(element.totalcost>0)
           c=element.totalcost/1000;
          if(c>0)
            datatablelinecost.push([this.month[m],c])
          else
           datatablelinecost.push([this.month[m],c])
       
      
          var fcolor='#F5A9A9';
          var bcolor='#F7F2E0';
          if(element.utilization<=60)
            {
               fcolor='#F4D03F';
               bcolor='#FCF3CF';
            }
            else if (element.utilization>60&&element.utilization<81){
               fcolor='#7DCEA0';
               bcolor='#D5F5E3';
            }
            
            var f=0,u=element.utilization;
            if((element.utilization)<100)
               f=100-element.utilization;
            //  u=100;

          // datatablelutilization.push([this.month[m],u,fcolor,f,bcolor])
         

      //  if(cnt<=0)
      //   datatablelutilization= [["Month","Utilization (%)",{role:'annotation'}]];

                 
        this.totalinvoice=this.totalinvoice+element.totalcost;

       this.totalvolume=this.totalvolume + element.totalcarton;
       
       m=m+1;


    });


    var c=0;
    datatableline.slice(1,datatableline.length-1).forEach(element => {
      
      //console.log(element[1]);
       if(element[1]!=undefined)
         c=c+1;

    });
    //console.log(c)
    if(c==0)
      {
        datatableline=[["Month","Carton Count (k)"]];
      }
    Linechart.dataTable=datatableline;
    
    this.year_line_volume_graph=JSON.parse(JSON.stringify(Linechart));
    Linechart.options.height=60

    this.year_line_volume_graph_small=JSON.parse(JSON.stringify(Linechart));
    Linechart.options.height=30
    this.year_line_volume_graph_ssmall=JSON.parse(JSON.stringify(Linechart));

    //console.log(this.volumechart)

    Linechart.dataTable=datatablelinecost;
    Linechart.options.colors=['#F5B7B1']
  
    Linechart.options.height=80;

    this.year_line_cost_graph=JSON.parse(JSON.stringify(Linechart));

    this.year_line_cost_graph_small=JSON.parse(JSON.stringify(Linechart));
    Linechart.options.height=30;
    this.year_line_cost_graph_ssmall=JSON.parse(JSON.stringify(Linechart));

    console.log(this.totalsbylocpartenr);
   
    // Linechart.dataTable=this.costchart.datatable;
    // this.year_line_cost_graph =JSON.parse(JSON.stringify(Linechart));
// utilization
///


 this.graphlist=[]

  this.totalsbytypes.forEach(types => {

    var Linechart = {
      chartType: 'LineChart',
      dataTable: [],
      options: {
        options: {

          title: "Volume vs Invoice ($)",
          
        },
        legend: 'none',

        // hAxis: {title: 'Volume',textPosition:'none'},
        // vAxis: {title: 'Invoice ($)',textPosition:'none'},
        hAxis: {
          baselineColor: 'transparent',  gridlines: {
          color: 'none'

        },textPosition:'none'},
        vAxis :{
          gridlines: {
            color: 'transparent'
          },textPosition:'none'
        },
        pointsize:3,
        

        //series: {},
        // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
        
        colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589'],
        // ,
        'chartArea': {'width': '100%', 'height': '100%'},
      height:60
      }

    }



    var datatableline= [["Month","Carton Count (k)"]]
    var datatablelinecost= [["Month","Invoice ($)"]]

    var m=0;
    types.monthlist.forEach(month => {

      var v=undefined;
      if(month.totalcarton>0)
        v=month.totalcarton/1000;

       if(v>0)
         datatableline.push([this.month[m],v])
      else 
      datatableline.push([this.month[m],v])


         var c=undefined;
         if(month.totalcost>0)
           c=month.totalcost/1000;
          if(c>0)
            datatablelinecost.push([this.month[m],c])
          else
           datatablelinecost.push([this.month[m],c])

    

      m=m+1;
    });    
     
      
      // this.year_line_volume_graph_small=JSON.parse(JSON.stringify(Linechart));
        Linechart.options.height=30
        Linechart.dataTable=datatablelinecost;
        var cost=JSON.parse(JSON.stringify(Linechart));

        Linechart.options.height=30
        Linechart.dataTable=datatableline;
        Linechart.options.colors=['#AF601A']
        var volumechart=JSON.parse(JSON.stringify(Linechart));
 
        this.graphlist.push({types:types,cost:cost,volume:volumechart})
      // this.year_line_volume_graph_ssmall=JSON.parse(JSON.stringify(Linechart));

      // //console.log(this.volumechart)

      // Linechart.dataTable=datatablelinecost;
      // Linechart.options.colors=['#F5B7B1']
    
      // this.year_line_cost_graph=JSON.parse(JSON.stringify(Linechart));
      // Linechart.options.height=60;
      // this.year_line_cost_graph_small=JSON.parse(JSON.stringify(Linechart));




});
console.log(this.graphlist);
///
var Guage = {
  chartType: 'Gauge',
  dataTable: [['Label', 'Value'],
  
  ['BTS', 55]],
  formatters: [
    {
      columns: [1],
      type: 'NumberFormat',
      options: {
        suffix: '%',
        fractionDigits: 0
      }
    }
  ],
  options: {
    options: {

      title: "Volume vs Invoice ($)",
      
    },
   
    legend: 'none',
  
   

    //series: {},
    // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
    
    colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589'],
    // ,
    height:80,
    'chartArea': {'width': '100%', 'height': '70%'},
  
  redFrom: 81,
  redColor:'#F1948A',
  redTo: 100,
  yellowFrom: 0,
  yellowColor:'rgb(244, 208, 64)',
  yellowTo: 60,
  greenFrom:61,greenColor:'#7DCEA0',
  greenTo:80,
  minorTicks: 5
  }

 }

 this.utlizationd=parseFloat(this.utlizationd.toFixed(0))
 var utilization:any=this.utlizationd;
    //console.log(this.selectedgraph)
    var dataTable =[['Type','Utilization']]
    dataTable.push(["Overall",utilization])

     if(utilization>100){
      Guage.options.redColor='#B03A2E'
     }
     if((utilization)<40){
      Guage.options.yellowColor='#F9E79F'

   }
    Guage.dataTable=dataTable;
   this.yearutlization=Guage;


   console.log(this.region);
  //  Geo Charts
  var Geo = {
    chartType: 'GeoChart',
    dataTable: [
      ['Continent', 'Space',{ role: 'tooltip' }]
    ],
    // options: {
    //   region: 'world', // Display the world map
    //   colorAxis: { colors: ['#e0f7fa', '#006064'] } // Color range
    // },
    tooltip: { isHtml: true },
    height:230,

    // chartType: 'GeoChart',
    // dataTable: [
    //   ['Country', 'Population'],
    //   ['Central America', 1415045928],
    //   ['Eastern Europe', 1415045928],
    //   ['Western Asia', 313345928],
    //   ['Central Asia', 1415045928],
      
    //   ['South-Eastern Asia', 1415045928],

    // ],
    options: {
      tooltip: { isHtml: true ,textStyle: {color: '#FFFFFF'}},

      region: 'world', // Focus on the world
      resolution: 'continents', // Show sub-continents
      colorAxis: {colors: ['#EBF5FB', '#2471A3']} // Color range for the chart#EA8D8D → #A890FE
    },
  
   }

   var datatablegeo= [['Continent', 'Space',{ role: 'tooltip' ,p: { html: true } }]]
   var datatablegeo_cost= [['Continent', 'Cost',{ role: 'tooltip',p: { html: true }  }]]
   var datatablegeo_volume= [['Continent', 'Volume',{ role: 'tooltip',p: { html: true }  }]]

 
    this.region.forEach(element => {
      
      var region=element.type.split("/")[1];      
      if(region.length==1)
        {
          region="00"+region;
        }
        else if(region.length==2){
          region="0"+region;
        }
        else{
          region=region;
        }

        var r="";
        if(region=="142")
          r='Asia'
        else if(region=="150")
          r='Europe'
        else if(region=="019")
          r='Americas'


      datatablegeo.push([region,element.space,"<p><b>"+element.type.split("/")[0]+"</b> Total Space is <b>"+element.space.toLocaleString(this.lc,this.opt)+"</b> Sq. ft</p>"])
      datatablegeo_cost.push([region,element.totalcost,"<p><b>"+element.type.split("/")[0]+" Total Invoice is $ "+element.totalcost.toLocaleString(this.lc,this.opt)+"</p>"])
      datatablegeo_volume.push([region,element.totalcarton,"<p><b>"+element.type.split("/")[0]+" Total Volume is "+element.totalcarton.toLocaleString(this.lc,this.opt)+"</p>"])


    });
    Geo.dataTable=datatablegeo;
    this.Geo=JSON.parse(JSON.stringify(Geo));
    Geo.dataTable=datatablegeo_cost;
    Geo.options.colorAxis.colors=['#EBDEF0','#7D3C98']
    this.Geo_cost=JSON.parse(JSON.stringify(Geo));
    
    Geo.dataTable=datatablegeo_volume;
    Geo.options.colorAxis.colors=['#FDF2E9','#CA6F1E']

    this.Geo_voulme=JSON.parse(JSON.stringify(Geo));
    console.log(Geo.dataTable);
    this.locflist=this.totalsbylocpartenr.slice(0,6);
    console.log(this.locflist)


    console.log(this.partnersummary);

    var barchart = {
      chartType: 'ColumnChart',
      dataTable: [],
      options: {
        options: {
  
          title: "Volume vs Invoice ($)",
          
        },
        annotations: {
          textStyle: {
            fontSize: 12,
            
            color: '#000000'
          }},
        
        // hAxis: {title: 'Volume',textPosition:'none'},
        // vAxis: {title: 'Invoice ($)',textPosition:'none'},
        // hAxis: {  gridlines: {
        //   color: 'transparent'

        // }},
        isStacked: true,

        vAxis :{
          gridlines: {
            color: 'transparent'
          },textPosition:'none'
        },
        pointsize:3,
        legend: { position: 'top', alignment: 'center' },

 
        //series: {},
        // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
        
        colors: ['#85C1E9','#73C6B6','#FAD7A0','#CCD1D1','#F5B7B1','#17A589'],
        // ,
        'chartArea': {'width': '88%', 'height': '80%'},
      height:230
      }
  
    }

    // var barchartinvoice = {
    //   chartType: 'ColumnChart',
    //   dataTable: [],
    //   options: {
    //     options: {
  
    //       title: "Volume vs Invoice ($)",
          
    //     },
    //     annotations: {
    //       textStyle: {
    //         fontSize: 12,
            
    //         color: '#000000'
    //       }},
        
    //     // hAxis: {title: 'Volume',textPosition:'none'},
    //     // vAxis: {title: 'Invoice ($)',textPosition:'none'},
    //     // hAxis: {  gridlines: {
    //     //   color: 'transparent'

    //     // }},
    //     isStacked: true,

    //     vAxis :{
    //       gridlines: {
    //         color: 'transparent'
    //       },textPosition:'none'
    //     },
    //     pointsize:3,
    //     legend: { position: 'top', alignment: 'center' },

 
    //     //series: {},
    //     // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
        
    //     colors: ['#AF7AC5','#16A085','#F4D03F','#E67E22','#2980B9','#17A589'],
    //     // ,
    //     'chartArea': {'width': '88%', 'height': '80%'},
    //   height:420
    //   }
  
    // }

    var dataTablePartner=[]
    var dataTablePartnerin=[]

    var heading:any= ["Month"]
    this.partnersummary.forEach(element => {
      heading.push(element.partner);
    });
    heading.push({role:'annotation'});
    dataTablePartner.push(heading);
    dataTablePartnerin.push(heading);
    var m=0;
    this.partnersummary[0].monthlist.forEach(month => {

      var list=[this.month[m]]
      var listi=[this.month[m]]

      
      var tc=0,ti=0;
      this.partnersummary.forEach(partner => {
        list.push(partner.monthlist[m].totalcarton)
        listi.push(partner.monthlist[m].totalcost)

        // dataTablePartner.push()
        tc=tc+partner.monthlist[m].totalcarton;
        ti=ti+partner.monthlist[m].totalcost;
      });
      list.push((tc/1000).toLocaleString(this.lc,this.opt)+"K")
      listi.push((ti/1000).toLocaleString(this.lc,this.opt)+"K")

      dataTablePartner.push(list)
      dataTablePartnerin.push(listi)

      m=m+1;

    });

    barchart.dataTable=dataTablePartner;

    this.partnersummarygraph=JSON.parse(JSON.stringify(barchart));
    // barchart.options.legend='none';
    barchart.dataTable=dataTablePartnerin;
    
    this.partnersummarygraphinovice=JSON.parse(JSON.stringify(barchart));


   }

    partnersummarygraph:any;
    partnersummarygraphinovice:any;


  go_config()
  {
    this.router.navigateByUrl('/mle-training');
  
  }
 
  GOtoNExtPage_2() {
    
    this.router.navigateByUrl('/operational-concern');
  }
  gototraining(){
    this.router.navigateByUrl('/dailytraining');

  }
  GOtoNExtPage_1() {
    
    this.router.navigateByUrl('/dashboard');
  }
  GOtoNExtPage_P()
  {
    this.router.navigateByUrl('/operationasl-concern1');
  }
  GOtomessage() {
      
    this.router.navigateByUrl('/messages');
  }
  
  
  
  go_config1()
  {
    this.router.navigateByUrl('/userprofile');
  }
  gotwconfig(){
    this.router.navigateByUrl('/warehouseconfig');

  }
  
}
